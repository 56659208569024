<template>
  <el-card shadow="never" v-loading="loading">
    <div slot="header" class="iconfont icon-TerminalEquipment">&nbsp;终端列表</div>
    <div class="card_header_flex">
      <div>
        <el-dropdown
          placement="bottom"
          trigger="click"
          @command="onSubmitBatch"
          v-show="dataLineSelections.length>0"
        >
          <el-button type="primary" size="small">
            批量操作
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="enabled">启用</el-dropdown-item>
            <el-dropdown-item command="disabled">禁用</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>&nbsp;
        <el-dropdown
          placement="bottom"
          trigger="click"
          @command="onSubmitBatch"
          v-show="dataLineSelections.length>0 "
        >
          <el-button size="small" plain>
            批量状态
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="lived">正常</el-dropdown-item>
            <el-dropdown-item command="ban">停机</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>&nbsp;
        <el-button
          size="small"
          @click="onSubmitBatch('del')"
          v-show="dataLineSelections.length>0"
          plain
        >批量删除</el-button>
      </div>
      <div>
        <el-select v-model="selectValue" placeholder="请选择查询类型" size="small" @change="initLoad">
          <el-option
            v-for="item in selectType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>&nbsp;
        <el-input
          v-model="search"
          placeholder="请输入查询条件"
          suffix-icon="el-icon-search"
          style="width:300px"
          size="small"
          @change="initLoad"
        ></el-input>
      </div>
    </div>
    <el-table
      ref="table"
      :data="list.slice((currentPage-1)*pageSize,currentPage*pageSize)"
      size="mini"
      @selection-change="selectionLineChangeHandle"
      row-key="id"
    >
      <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
      <el-table-column prop="clientId" label="客户端ID" show-overflow-tooltip></el-table-column>
      <el-table-column prop="room" label="房间号">
        <template slot-scope="scope">
          <el-input
            v-if="scope.row.isSelectedRoom"
            size="mini"
            v-model="scope.row.room"
            @focus="focusEvent(scope.row)"
            @blur="blurEvent(scope.row)"
            v-focus
          ></el-input>
          <span v-else>
            {{ scope.row.room }}
            <i
              class="el-icon-edit-outline"
              style="color:#5e72f9;font-size:14px;margin-left:10px;cursor:pointer;vertical-align:middle"
              @click="cellClick(scope.row)"
            ></i>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="mac" label="客户端mac地址" show-overflow-tooltip></el-table-column>
      <el-table-column prop="wirelessMac" label="无线mac地址" show-overflow-tooltip></el-table-column>
      <el-table-column prop="onlineState" label="在线状态">
        <template slot-scope="scope">{{scope.row.onlineState=='online'?'在线':'离线'}}</template>
      </el-table-column>
      <el-table-column prop="iptvVersion" label="iptv版本"></el-table-column>
      <el-table-column prop="clientVersion" label="客户端版本"></el-table-column>
      <el-table-column prop="clientState" label="是否启用">
        <template slot-scope="scope">
          <span
            :class="scope.row.clientState=='enabled'?'':'closeState'"
          >{{scope.row.clientState=='enabled'?'已启用':'已禁用'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="expireTime" label="过期时间" width="160">
        <template slot-scope="scope">
          <el-date-picker
            v-if="scope.row.isSelectedTime"
            v-model="scope.row.expireTime"
            v-focus
            type="date"
            placeholder="选择日期"
            style="width:100%"
            :clearable="false"
            :editable="false"
            size="mini"
            @focus="focusTimeEvent(scope.row)"
            @blur="blurTimeEvent(scope.row)"
            value-format="yyyy-MM-dd"
          ></el-date-picker>

          <span v-else>
            {{scope.row.expireTime}}
            <i
              class="el-icon-edit-outline"
              style="color:#5e72f9;font-size:14px;margin-left:10px;cursor:pointer;vertical-align:middle"
              @click="cellTimeClick(scope.row)"
            ></i>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="expireState" label="设备状态">
        <template slot-scope="scope">
          <span v-if="scope.row.expireState=='lived'">正常</span>
          <span v-if="scope.row.expireState=='expired'" class="closeState">已过期</span>
          <span v-if="scope.row.expireState=='ban'" class="closeState">停机</span>
          <el-dropdown placement="bottom" trigger="click" @command="onSubmitExpireState">
            <span class="el-dropdown-link">
              <i
                class="el-icon-edit-outline"
                style="color:#5e72f9;font-size:14px;margin-left:10px;cursor:pointer;vertical-align:middle"
              ></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="composeValue(scope.row,'lived')">正常</el-dropdown-item>
              <el-dropdown-item :command="composeValue(scope.row,'ban')">停机</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
      <el-table-column prop="playingChannel" label="当前操作"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="onSubmitSate('disabled',scope.row)"
            v-if="scope.row.clientState=='enabled'"
          >禁用</el-button>
          <el-button type="text" size="small" @click="onSubmitSate('enabled',scope.row)" v-else>启用</el-button>
          <el-button type="text" size="small" @click="onSubmitDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page_right">
      <el-pagination
        background
        layout="total,prev, pager, next ,jumper"
        :total="list.length"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </el-card>
</template>
<script>
import {
  getTerminalApi,
  editTerminalApi,
  delTerminalApi,
  batchEditTerminalApi,
  batchDelTerminalApi
} from "../../api/data";
import Aes from "../../utils/aes";
export default {
  data() {
    return {
      loading: true,
      search: "", //查询条件 可查 userId nickName mobile
      currentPage: 1, //当前页
      pageSize: 10, //一页显示多少条
      selectValue: "all",
      selectType: [
        {
          value: "all",
          label: "全部终端"
        },
        {
          value: "online",
          label: "在线终端"
        },
        {
          value: "offline",
          label: "离线终端"
        },
        {
          value: "expired",
          label: "到期终端"
        },
        {
          value: "disabled",
          label: "禁用终端"
        },
        {
          value: "inactive",
          label: "不活跃终端"
        }
      ],
      list: [],
      dataLineSelections: [] //表格选中集合
    };
  },
  computed: {
    //场所信息
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    },
    //登录账户信息
    userInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.userInfo));
    }
  },
  //自定义指令对文本框添加焦点
  directives: {
    focus: {
      inserted: function(el) {
        el.querySelector("input").focus();
      }
    }
  },
  created() {
    this.initLoad();
  },
  methods: {
    initLoad() {
      getTerminalApi({
        groupId: this.placesInfo.groupId,
        param: this.search,
        clientType: this.selectValue
      }).then(res => {
        this.loading = false;
        this.list = res.data.map(item => {
          return {
            ...item,
            isSelectedRoom: false,
            isSelectedTime: false
          };
        });
      });
    },

    //表格多选框选中事件
    selectionLineChangeHandle(val) {
      const selectionList = [];
      for (let i = 0; i < val.length; i++) {
        selectionList.push(val[i].id);
      }
      this.dataLineSelections = selectionList;
    },

    //批量操作
    onSubmitBatch(command) {
      if (this.dataLineSelections.length == 0) {
        this.$message.warning("请至少勾选一项");
        return;
      }
      if (command == "enabled") {
        //启用
        batchEditTerminalApi({
          ids: this.dataLineSelections,
          clientState: "enabled"
        }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      } else if (command == "disabled") {
        //禁用
        batchEditTerminalApi({
          ids: this.dataLineSelections,
          clientState: "disabled"
        }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      } else if (command == "lived") {
        //设备状态正常
        batchEditTerminalApi({
          ids: this.dataLineSelections,
          expireState: "lived"
        }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      } else if (command == "ban") {
        //设备状态停机
        batchEditTerminalApi({
          ids: this.dataLineSelections,
          expireState: "ban"
        }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      } else {
        //删除
        this.$confirm("此操作将永久删除该终端, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          batchDelTerminalApi({
            ids: this.dataLineSelections
          }).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.initLoad();
              this.$refs.table.clearSelection();
            }
          });
        });
      }
    },

    //删除终端
    onSubmitDel(obj) {
      this.$confirm("此操作将永久删除该终端, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delTerminalApi({ id: obj.id }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      });
    },

    //修改状态
    onSubmitSate(val, obj) {
      editTerminalApi({
        id: obj.id,
        groupId: obj.groupId,
        clientState: val
      }).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoad();
        }
      });
    },

    //编辑房间号事件
    cellClick(row) {
      row.isSelectedRoom = !row.isSelectedRoom;
    },
    //触发焦点记录用户聚焦前的值
    focusEvent(row) {
      row.oldRoom = row.room;
    },
    //失去焦点事件
    blurEvent(row) {
      row.isSelectedRoom = !row.isSelectedRoom;
      //判断前后两次的值是否一样
      if (row.room !== row.oldRoom) {
        //修改房间号
        editTerminalApi(row).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      }
    },

    //编辑过期时间事件
    cellTimeClick(row) {
      row.isSelectedTime = !row.isSelectedTime;
    },
    //触发焦点记录用户聚焦前的值
    focusTimeEvent(row) {
      row.oldExpireTime = row.expireTime;
    },
    //失去焦点事件
    blurTimeEvent(row) {
      row.isSelectedTime = !row.isSelectedTime;
      //判断前后两次的值是否一样
      if (row.expireTime !== row.oldExpireTime) {
        //修改过期时间
        editTerminalApi(row).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      }
    },

    //修改设备状态下拉数据处理
    composeValue(obj, state) {
      return { obj: obj, state: state };
    },
    //提交修改设备状态
    onSubmitExpireState(command) {
      var obj = { ...command.obj };
      obj.expireState = command.state;
      editTerminalApi(obj).then(res => {
        if (res) {
          this.$message.success(res.msg);
          this.initLoad();
        }
      });
    },
    //选择第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    }
  }
};
</script>
<style lang='scss'>
.closeState {
  color: #f56c6c;
}
</style>